export default [
  {
    title: "Home",
    route: "home",
    icon: "HomeIcon",
    user_types: [],
  },
  {
    title: "RecipeMaster",
    route: "recipe-list",
    icon: "BookIcon",
    user_types: [],
  },
];
