export default [
  {
    title: "Sidebar.Home",
    route: "home",
    icon: "HomeIcon",
    action: "read",
    resource: "home",
  },
  {
    title: "Sidebar.Food",
    icon: "CoffeeIcon",
    children: [
      {
        title: "Sidebar.RecipeMaster",
        route: "recipe-list",
        icon: "CoffeeIcon",
        action: "read",
        resource: "food",
      },
      {
        title: "Sidebar.RecipeMain",
        route: "recipe-main-list",
        icon: "CoffeeIcon",
        action: "read",
        resource: "food",
      },
      {
        title: "Sidebar.IngredientMaster",
        route: "ingredient-list",
        icon: "CoffeeIcon",
        action: "read",
        resource: "food",
      },
      {
        title: "Sidebar.IngredientMain",
        route: "ingredient-main-list",
        icon: "CoffeeIcon",
        action: "read",
        resource: "food",
      },
      {
        title: "Sidebar.IngredientCategory",
        route: "ingredient-cat-list",
        icon: "CoffeeIcon",
        action: "read",
        resource: "food",
      },
      // {
      //   title: "Sidebar.RecipeIngredient",
      //   route: "recipe-ingredient-list",
      //   icon: "CoffeeIcon",
      //   action: "read",
      //   resource: "food",
      // },
      // {
      //   title: "Sidebar.IngredientItem",
      //   route: "ingredient-item-list",
      //   icon: "CoffeeIcon",
      //   action: "read",
      //   resource: "food",
      // },
      // {
      //   title: "Sidebar.Item", 
      //   route: "item-list",
      //   icon: "CoffeeIcon",
      //   action: "read",
      //   resource: "food",
      // },
    ],
  },
  {
    title: "Sidebar.Masters",
    icon: "BoxIcon",
    module: "masters",
    children: [
      {
        title: "Sidebar.Language",
        route: "language-list",
        icon: "BoxIcon",
        action: "read",
        resource: "masters",
      },
      {
        title: "Sidebar.Country",
        route: "country-list",
        icon: "BoxIcon",
        action: "read",
        resource: "masters",
      },
      {
        title: "Sidebar.Cuisine",
        route: "cuisine-list",
        icon: "BoxIcon",
        action: "read",
        resource: "masters",
      },
      {
        title: "Sidebar.Currency",
        route: "currency-list",
        icon: "BoxIcon",
        action: "read",
        resource: "masters",
      },
      // {
      //   title: "Sidebar.Unit",
      //   route: "unit-list",
      //   icon: "BoxIcon",
      //   action: "read",
      //   resource: "masters",
      // },
      {
        title: "Sidebar.Format",
        route: "format-list",
        icon: "BoxIcon",
        action: "read",
        resource: "masters",
      },
      {
        title: "Sidebar.LanguageFormat",
        route: "langformat-list",
        icon: "BoxIcon",
        action: "read",
        resource: "masters",
      },
      {
        title: "Sidebar.LanguageUnit",
        route: "langunit-list",
        icon: "BoxIcon",
        action: "read",
        resource: "masters",
      },
      {
        title: "Sidebar.Category",
        route: "category-list",
        icon: "BoxIcon",
        action: "read",
        resource: "masters",
      },
      {
        title: "Sidebar.LanguageCategory",
        route: "lang-category-list",
        icon: "BoxIcon",
        action: "read",
        resource: "masters",
      },
      {
        title: "Sidebar.Retailer",
        route: "retailer-list",
        icon: "BoxIcon",
        action: "read",
        resource: "masters",
      },
      {
        title: "Sidebar.Store",
        route: "store-list",
        icon: "BoxIcon",
        action: "read",
        resource: "masters",
      },
    ],
  },
  // {
  //   title: "Website Users",
  //   route: "website-users-list",
  //   icon: "UsersIcon",
  //   action: "read",
  //   resource: "website_users",
  // },
  {
    title: "Sidebar.Users",
    icon: "UsersIcon",
    children: [
      {
        title: "Sidebar.User",
        route: "user-list",
        icon: "UserIcon",
        action: "read",
        resource: "user",
      },
      {
        title: "Role",
        route: "role-list",
        icon: "UserPlusIcon",
        action: "read",
        resource: "user",
      },
      {
        title: "Website Users",
        route: "website-users-list",
        icon: "UsersIcon",
        action: "read",
        resource: "website_users",
      },
    ],
  },
];
